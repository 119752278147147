import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification"
export default class extends Controller {
  static targets = ['new', 'content', 'notification']

  connect() {
    
  }

  clearTimeouts() {
    clearTimeout(this.hideTimeout);
    clearTimeout(this.removeTimeout);
  }

  show(e) {
    this.clearTimeouts()

    if(e) {
      this.message(e.detail.type, e.detail.description);
    }

    this.notificationTarget.classList.add('notification--show', 'notification--active')

    this.hideTimeout = setTimeout(() => {
      this.hide()
    }, 5000);
  }

  hide() {
    this.notificationTarget.classList.remove('notification--show')
    this.notificationTarget.classList.add('notification--hide')

    this.removeTimeout = setTimeout(() => {
      this.notificationTarget.classList.remove('notification--active')
      this.notificationTarget.classList.remove('notification--hide')
    }, 1000);
  }

  newTargetConnected() {
    this.message(this.newTarget.dataset.kind, this.newTarget.innerHTML);

    this.newTarget.remove()

    this.show();
  }

  message(type, message) {
    let icon = 'circle-check'

    switch(type) {
      case 'error':
      case 'alert':
        icon = 'circle-exclamation'
      break;
    }

    this.notificationTarget.classList.add(`notification--${type}`)

    this.contentTarget.innerHTML = `<div class="inline"><span class="icon text-lg"><i class="fa-${icon} fas"></i></span><span>${message}</span></div>`
  }
}
