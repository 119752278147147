import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropzone"
export default class extends Controller {
  connect() {
    ['dragenter', 'dragend', 'drop', 'dragleave'].forEach(eventName => {
      this.element.addEventListener(eventName, this.event.bind(this));
    });
  }

  event(e) {
    this.element.classList.toggle('dropzone--hover', e.type === 'dragenter');
  }
}
