import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="visibility"
export default class extends Controller {
  static targets = ["element", "input"];
  static values = {
    type: String};

  connect() {
    this.element[this.identifier] = this;

    if(!this.inputTarget.dataset.action) this.inputTarget.dataset.action = ''

    if(!this.hasTypeValue) this.typeValue = this.inputTarget.type;

    this.inputTargets.forEach( element => { element.dataset.action += " visibility#update"; })

    this.update();
  }

  condition(element) {
    let value = element.dataset.visibilityValue;

    if (!value) value = this.data.get("value");

    if (this.typeValue == "checkbox") {
      return this.inputTarget.checked;
    } else if (this.typeValue == "radio") {
      const elements = [...this.element.closest('form').querySelectorAll(`input[name="${this.inputTarget.name}"]`)]

      return (elements.filter(e => e.checked).length > 0)
    } else if (this.typeValue == "password" || !value) {
      if (this.data.has("empty")) return this.inputTarget.value == "";
      else return this.inputTarget.value != "";
    } else {
      return value.indexOf("|" + this.inputTarget.value + "|") > -1;
    }
  }

  update() {
    if (!this.hasElementTarget) return;

    this.elementTargets.forEach((element) => {
      let condition = this.condition(element);
      
      if (element.dataset.visibilityNot) condition = !condition;

      element.classList.toggle("!hidden", !condition);
    });
  }
}
