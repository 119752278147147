import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="files"
export default class extends Controller {
  static targets = ['list', 'input', 'template', 'item', 'button', 'formField', 'dropzone', 'buttonFailed']
  static values = { redirect: String, uploaded: String, fileSize: Number, contentTypes: Array }

  connect() {
    this.update();

    this.busy = false;
  }

  update() {
    this.updateButton()
  }

  updateButton() {
    this.buttonTarget.classList.toggle('hidden', this.finished != undefined || this.itemTargets.length == 0);
  }

  append(e) {
    const files = this.inputTarget.files;

    let errors = [];

    [...files].forEach(file => {
      let result = this.validate(file);

      if (result !== true) {
        errors.push({ file: file.name, error: result });
        return;
      }

      let dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)

      let item = this.templateTarget.content.cloneNode(true);
      item.querySelector('.file__name').innerHTML = file.name
      item.querySelector('.file__size').innerHTML = ((file.size / 1024) / 1024).toFixed(4) + ' MB';
      item.querySelector('input').files = dataTransfer.files
      this.listTarget.appendChild(item);
    });

    if (errors.length > 0) {
      let message = errors.map(error => {
        return `${error.file}\n${error.error}`;
      }).join("\n\n");

      alert(message);
    }

    this.inputTarget.value = null;

    this.update();
  }

  validate(file) {
    if (this.fileSizeValue && file.size > this.fileSizeValue) {
      return 'File is too big';
    }

    if (this.contentTypesValue && file.type && !this.contentTypesValue.includes(file.type)) {
      return `File type ${file.type} not allowed`;
    }

    return true;
  }

  remove(e) {
    e.preventDefault();
    e.target.closest('.file').remove();

    this.update();
  }

  uploadAll(e) {
    this.finished = 0;

    e.preventDefault();
    this.itemTargets.forEach(item => {
      this.upload(item);
    });

    this.dropzoneTarget.remove();

    this.formFieldTargets.forEach(field => {
      field.disabled = true;
    });

    this.update();
  }

  uploadFinished() {
    if (this.itemTargets.length == this.finished) {
      if (this.uploadedValue) {
        fetch(this.uploadedValue + '?touch=1');

        this.element.closest('turbo-frame').setAttribute('src', this.uploadedValue);
      }

      // if(this.redirectValue) {
      //   setTimeout( () => {
      //     if(this.element.closest('.modal')) {
      //       document.documentElement.modal.close({force: true});
      //     } else {
      //       window.location.href = this.redirectValue;
      //     }
      //   }, 1000);
      // }
    }
  }

  upload(item) {
    let file = item.querySelector('input').files[0];

    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('progress', (e) => {
      let progress = e.loaded / e.total * 100;

      item.querySelector('.file__progress__percentage').innerHTML = progress.toFixed(0).replace('.', ',') + '%';
      item.querySelector('.file__progress__bar div').style.width = progress + '%';
    });

    xhr.upload.addEventListener('loadstart', (e) => {
      item.classList.add('file--started');
      this.buttonTarget.classList.add('hidden')
    });

    xhr.addEventListener('loadend', (e) => {
      let status = e.target.status;

      console.log(status)

      switch (status) {
        case 200:
          item.classList.add('file--uploaded');

          this.finished++;
          this.uploadFinished();
          break;
        case 422:
          let result = JSON.parse(e.target.responseText);

          this.error(item, result);
          break;
        default:
          this.error(item, { message: 'An error occurred' })
      }
    });

    xhr.open('POST', this.element.action, true);
    xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));

    const fileData = new FormData();
    fileData.append("attachment[file]", file)
    fileData.append("attachment[description]", item.querySelector('textarea').value)

    this.formFieldTargets.forEach(field => {
      fileData.append(field.name, field.value)
    });

    xhr.send(fileData);
  }

  error(item, result) {
    item.classList.add('file--error');

    item.querySelector('.file__size').innerHTML = result.message

    this.buttonFailedTarget.classList.remove('hidden');
    this.buttonFailedTarget.href = this.buttonFailedTarget.href.replace('-FOLDER-', this.formFieldTargets[0].value);
  }
}
