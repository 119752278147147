import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  connect() {
  }

  form() {
    return this.element.form;
  }

  sort(e) {
    e.preventDefault();

    let input = this.element.querySelector('#filter_sort')
    let th = e.target.closest('th')

    let value = th.dataset.sort

    if(!input.value.includes('-asc')){
      value += '-asc'
    }

    input.value = value

    this.form().submit();
  }
}
