import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    this.element[this.identifier] = this;

    this.submitWithDebounce = debounce(this.submit, 500);
  }

  submit() {
    this.element.requestSubmit();
  }
}
