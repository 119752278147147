import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo"
export default class extends Controller {
  connect() {

    Turbo.StreamActions.redirect = function () {
      //document.documentElement.modal.close({force: true})
      window.location.href = this.target;
    };

    document.addEventListener('turbo:before-stream-render', (e) => {
      const event = new CustomEvent('notification', { detail: { type: 'success', description: 'The page has been updated!' } })

      window.dispatchEvent(event)
    });

  }
}
